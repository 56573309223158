import {toastErrorMessage} from '../../packs/toast';
import {getBackgroundJobStatus, getLoadReports} from '../common/api_requests';
import IdManager from '../common/id_manager';

$(document).on('turbolinks:load', () => {
  if ($('.load-report-index').length === 0) return;

  window.commonJS.handleInitializeSelect2();
  window.commonJS.handleCollapseLink();
  window.commonJS.initializeDateRangePicker(['date_processed']);

  initSelectAllStatusesOption();
  initLoadReportDataTable();
  handleFilterLoadReportDataTable();
  handleLoadReportResetFilter();
  handleExportLoadReport();
  handleLoadReportSelect();
  handleInitializeAdditionalFileModal();
  handleSelectAll();
});

let checkReportStatus, table;
const selectedLoads = new IdManager();
const selectedPages = new IdManager();

const initSelectAllStatusesOption = () => {
  const $el = $('#q_load_status_id_in');
  $el.prepend('<option value="select-all">All Statuses</option>');
  $el.on('select2:select', e => {
    const selectedValue = e.params.data.id;

    if (selectedValue === 'select-all') {
      const allValues = $('#q_load_status_id_in option').map((_, option) => $(option).val()).get();
      $el.val(allValues.filter(value => value !== 'select-all' && value !== '')).trigger('change');
    }
  });
};

const handleLoadReportSelect = () => {
  $('#load-report-table').on('click', '.load-module-checkbox', e => {
    const checkbox = $(e.currentTarget);
    if (checkbox.is(':checked')) {
      selectedLoads.addId(checkbox.val());
    } else {
      selectedLoads.removeId(checkbox.val());
    }
    updateCounter();
    const selectedCount = $('input.load-module-checkbox:checked').length;
    const isAllChecked = selectedCount === 20;
    updateSelectedPages(isAllChecked);
    $('#check_all').prop('checked', isAllChecked);
  });
};

const handleSelectAll = () => {
  $('#check_all').on('click', e => {
    const isChecked = e.target.checked;
    $('.load-module-checkbox:checkbox').each((_, checkbox) => {
      checkbox.checked = isChecked;

      if (isChecked) {
        selectedLoads.addId($(checkbox).val());
      } else {
        selectedLoads.removeId($(checkbox).val());
      }
    });
    updateSelectedPages(isChecked);
    updateCounter();
  });
};

const updateSelectedPages = isChecked => {
  if (isChecked) {
    selectedPages.addId(table.page());
  } else {
    selectedPages.removeId(table.page());
  }
};

const updateCounter = () => {
  $('#counter').text(selectedLoads.size());
};

const getExportOptions = mode => {
  if (mode !== 'full') return [];

  const checkedOptions = $('.export-options input[type="checkbox"]:checked');
  return $.map(checkedOptions, (item, _index) => item.value);
}

const handleExportLoadReport = () => {
  $('#export-load-report, #export-full-report-btn').click(e => {
    e.preventDefault();

    const loadModuleIds = selectedLoads.getAllIds();
    const message = 'No modules are selected. Are you sure you want to export all filtered load modules?';
    if (loadModuleIds.length === 0 && !confirm(message)) return;

    $('#overlay').addClass('d-flex');
    $('#additional-export-file').modal('hide');

    const searchParams = $('#load_module_search').serializeObject();
    const mode = e.currentTarget.id === 'export-full-report-btn' ? 'full' : 'basic';
    const exportOptions = getExportOptions(mode);

    const requestData = {
      mode,
      exportOptions,
      ...(loadModuleIds.length > 0
        ? {loadModuleIds}
        : {searchParams: searchParams.q})
    };

    getLoadReports(requestData)
      .done(data => {
        checkReportStatus = setInterval(
          handleCheckReportStatus,
          5000,
          data.background_job_id
        );
      })
      .fail(error => {
        console.error('Error:', error);
        toastErrorMessage('Something went wrong. Please try again later.');
      });
  });
};

const handleLoadReportResetFilter = () => {
  $('#load-report-filter-reset').click(() => {
    selectedLoads.clearIds();
    selectedPages.clearIds();
  });
};

const handleCheckReportStatus = background_job_id => {
  getBackgroundJobStatus(background_job_id)
    .done(data => {
      if (data.status === 'done') {
        handleReportStatusDone();

        window.location = `/admin/background_jobs/${background_job_id}/download_file`;
      } else if (data.status === 'failed') {
        handleReportStatusDone();
        showFriendlyErrorMessage();
      }
    })
    .fail(error => {
      console.error('Error:', error);

      handleReportStatusDone();
      showFriendlyErrorMessage();
    });
};

const showFriendlyErrorMessage = () => {
  toastErrorMessage('Something went wrong. Please try again later.');
};

const handleReportStatusDone = () => {
  $('#overlay').removeClass('d-flex');
  clearInterval(checkReportStatus);
};

const handleInitializeAdditionalFileModal = () => {
  $('.choose-additional-file').on('click', () => {
    $('.export-options input[type="checkbox"]').prop('checked', false);
  });
};

const handleFilterLoadReportDataTable = () => {
  $('.submit-load-report-filter').click(e => {
    e.preventDefault();

    selectedLoads.clearIds();
    selectedPages.clearIds();
    updateCounter();

    $('#load-report-table').DataTable().ajax.reload();
  });
};

const initLoadReportDataTable = () => {
  table = $('#load-report-table').DataTable({
    ordering: false,
    searching: false,
    paging: true,
    pageLength: 20,
    autoWidth: false,
    info: true,
    order: [[7, 'desc']],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: '/reporting/load_reports.json'
    },
    preDrawCallback: settings => {
      const api = settings.oInstance.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['q'] = $('#load_module_search').serializeObject()['q'];
      api.context[0].ajax.data['q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    drawCallback: settings => {
      const currentPageIndex = settings.oInstance.api().page();
      $('#check_all').prop('checked', selectedPages.hasId(currentPageIndex));
    },
    rowCallback: (row, data) => {
      if (selectedLoads.hasId(data.id.toString())) {
        $(row).find('.load-module-checkbox').prop('checked', true);
      }
    },
    columns: [
      {
        data: 'id',
        width: '1%',
        render: (id, _type, _row) => {
          return `
            <div class="col">
              <div class="row d-flex flex-row justify-content-center">
                <input
                  type="checkbox"
                  class="row-checkbox load-module-checkbox"
                  value="${id}">
              </div>
            </div>`;
        }
      },
      {data: 'inbound_customer_v_number'},
      {data: 'inbound_customer_company_name'},
      {data: 'pickup_address'},
      {data: 'date_processed'},
      {data: 'load_status_position'},
      {data: 'load_stewardship_program_name'},
      {
        data: 'load_number',
        render: (data, _type, _row) => {
          if (!data.id) return data.load_number;

          return `
            <a href="${`/admin/load_module_generals/${data.id}`}"
              target="_blank">
              ${data.load_number}
            </a>`;
        }
      },
      {data: 'load_carrier_name'},
      {data: 'carrier_cost'}
    ]
  });
};

const formatOrderDirection = datatableApi => {
  if (datatableApi.order().length === 0) return '';

  const columns = datatableApi.settings().init().columns;
  const order = datatableApi.order()[0];

  return `${columns[order[0]].data} ${datatableApi.order()[0][1]}`;
};
